<script lang="ts" setup>
import { computed } from 'vue'
import type Engagement from '~/src/Domain/Engagement/Engagement'
import type Organisation from '~/src/Domain/Organisation/Organisation'
import useEngagementSettingsNavigation
  from '~/src/UserInterface/Engagement/composables/useEngagementSettingsNavigation'

const props = defineProps<{
  organisation: Organisation
  engagement: Engagement
}>()

const { engagementSettingsGeneralLocalePath } = useEngagementSettingsNavigation(computed(() => props.organisation), computed(() => props.engagement))
</script>

<template>
  <ui-nav-item :to="engagementSettingsGeneralLocalePath">
    {{ $t('components.organisation.navbar_top.nav_engagement') }}
  </ui-nav-item>
</template>
